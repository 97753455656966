var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _vm.configError
        ? [
            _c("v-alert", { attrs: { type: "error" } }, [
              _vm._v(" Houve um erro de configuração. ")
            ])
          ]
        : [
            _c("div", [_vm._v("Você será redirecionado em breve.")]),
            _c("small", [
              _c("a", { on: { click: _vm.redirectToLandingPage } }, [
                _vm._v("clique aqui")
              ]),
              _vm._v(" se você não for redirecionado.")
            ])
          ],
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br"),
      _c("br")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }