<template>
    <div class="container">

        <br><br><br><br><br>

        <template v-if="configError">
            <v-alert type="error">
                Houve um erro de configuração.
            </v-alert>
        </template>
        <template v-else>
            <div>Você será redirecionado em breve.</div>
            <small><a @click="redirectToLandingPage">clique aqui</a> se você não for redirecionado.</small>
        </template>

        <!-- Filler para evitar que o footer fique estranho se demorar para fazer o redirecionamento -->
        <br><br><br><br><br>
        <br><br><br><br><br>
        <br><br><br><br><br>
        <br><br><br><br><br>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'RedirectToLandingPage',
    created() {
        this.redirectToLandingPage()
    },
    data: vm => ({
        configError: false,
    }),
    methods: {
        redirectToLandingPage() {
            if (!this.landingPageUrl) {
                this.configError = true
                return
            }

            // Para desenvolvimento, o frontend vai rodar na porta 8081
            // Em produção, vai rodar em um subdomínio app.cliente.com
            window.location = this.landingPageUrl
        },
    },
    computed: {
        ...mapGetters({
            landingPageUrl: 'company/landing_page_domain'
        }),
    },
}
</script>