<template>
    <v-container>
        <h3 class="title">{{ $t('views.welcome.title') }}</h3>
        <h4 class="subtitle font-weight-medium">{{ $t('views.welcome.subtitle') }}</h4>

        <v-divider class="my-3"></v-divider>

        <p>{{ $t('views.welcome.description1') }}</p>

        <template v-if="user.is_default_password">
            <v-banner two-line>
                <v-avatar
                    slot="icon"
                    color="deep-orange accent-4"
                    size="40"
                >
                    <v-icon color="white" small>fas fa-fw fa-lock</v-icon>
                </v-avatar>

                {{ $t('views.welcome.first_access_warning') }}

                <template v-slot:actions>
                    <v-btn text color="deep-orange accent-4" :to="{ name: 'settings' }">{{ $t('views.welcome.change_password') }}</v-btn>
                </template>
            </v-banner>
        </template>
        <template v-else>
            <v-btn dark color="deep-purple accent-4" :to="{ name: 'home' }">{{ $t('views.welcome.go_to_dashboard') }}</v-btn>
        </template>

    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'Welcome',
    computed: {
        ...mapGetters({
            user: 'auth/user',
        }),
    },
}
</script>