var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("h3", { staticClass: "title" }, [
        _vm._v(_vm._s(_vm.$t("views.welcome.title")))
      ]),
      _c("h4", { staticClass: "subtitle font-weight-medium" }, [
        _vm._v(_vm._s(_vm.$t("views.welcome.subtitle")))
      ]),
      _c("v-divider", { staticClass: "my-3" }),
      _c("p", [_vm._v(_vm._s(_vm.$t("views.welcome.description1")))]),
      _vm.user.is_default_password
        ? [
            _c(
              "v-banner",
              {
                attrs: { "two-line": "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                text: "",
                                color: "deep-orange accent-4",
                                to: { name: "settings" }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("views.welcome.change_password"))
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ],
                  null,
                  false,
                  1255154653
                )
              },
              [
                _c(
                  "v-avatar",
                  {
                    attrs: {
                      slot: "icon",
                      color: "deep-orange accent-4",
                      size: "40"
                    },
                    slot: "icon"
                  },
                  [
                    _c("v-icon", { attrs: { color: "white", small: "" } }, [
                      _vm._v("fas fa-fw fa-lock")
                    ])
                  ],
                  1
                ),
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("views.welcome.first_access_warning")) +
                    " "
                )
              ],
              1
            )
          ]
        : [
            _c(
              "v-btn",
              {
                attrs: {
                  dark: "",
                  color: "deep-purple accent-4",
                  to: { name: "home" }
                }
              },
              [_vm._v(_vm._s(_vm.$t("views.welcome.go_to_dashboard")))]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }